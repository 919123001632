import React from "react"

const FeedbackForm = () => {
  return (
    <section className="mx-auto max-w-4xl p-6 flex flex-col">
        <form name="lap-feedback-form" className="flex flex-col" data-netlify="true" data-netlify-honeypot="bot-field" method="post">
            <input type="hidden" name="form-name" value="lap-feedback-form" />
            <label className="flex flex-col mb-6">
              <h1 className="text-xl font-extrabold mb-6">Talk dirty to me (just kidding, please don't)!</h1>
              <textarea className="font-semibold w-full h-48 border-4 border-red-900 bg-gray-100 text-lg p-2" name="feedback" placeholder="Start a conversation!" required></textarea>
            </label>
            <label className="flex flex-col mb-6">
                <span className="font-bold">Your email (so I can reply!)</span>
                <input className="font-semibold p-2 w-full border-4 border-red-900 bg-gray-100 text-lg" name="email" type="email" size="55" placeholder="igetspammed@gmail.com" required></input>
            </label>
            <button className="btn-cta">Let's chat!</button>
        </form>
    </section>
  )
}

export default FeedbackForm
