import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeedbackForm from "../components/feedbackForm"

const BlogArticleCard = props => {
  const { title, description, url, date, readingTime, type, index } = props

  return (
      <div key={index} className="bg-white p-6 m-6 rounded-xl shadow-md flex flex-col place-content-between">
        <div className="bg-red-900 text-white font-semibold text-center rounded-lg w-28 mb-4">
          {type}
        </div>
        <div className="flex flex-col items-center mb-6 sm:flex-row">
          <div className="flex-shrink-0 w-8"><span role="img" aria-label="Blog post icon">📜</span></div>
          <h1 className="text-xl font-medium hover:text-gray-500">
            <Link to={url} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h1>
        </div>
        <p className="text-gray-700 font-normal mb-6">{description}</p>
        <div className="text-xs font-extralight">{`${date} • ${readingTime} min read`}</div>
      </div>
  )
}

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <div className="grid grid-flow-row auto-rows-fr xl:grid-cols-2 md:max-w-4xl md:mx-auto">
        {posts.map((post, index) => (
          <BlogArticleCard
            key={index}
            title={post.frontmatter.title || post.fields.slug}
            url={post.fields.slug}
            date={post.frontmatter.date}
            readingTime={post.frontmatter.readingTimeInMinutes}
            description={post.frontmatter.description || post.excerpt}
            type={post.frontmatter.articleType}
          />
        ))}
      </div>
      <FeedbackForm />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          readingTimeInMinutes
          articleType
        }
      }
    }
  }
`
